import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { QuicklinkStrategy } from 'ngx-quicklink'; // Quicklink added in order to try and fix performance / navigation issues
import { TranslatorService } from '../core/translator/translator.service';
import { SharedModule } from '../shared/shared.module';
import { PagesModule } from './pages/pages.module';
import { routes } from './routes';

@NgModule({
    imports: [
        SharedModule,
        RouterModule.forRoot(routes, { preloadingStrategy: QuicklinkStrategy }),
        PagesModule
    ],
    declarations: [],
    exports: [
        RouterModule
    ]
})

export class RoutesModule {
    constructor(tr: TranslatorService) {
    }
}
